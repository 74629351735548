import React from 'react';
import "./footer.css";

function index() {
    return (
        <div className='footer'>
            Made with &#10084; PentestGuy
        </div>
    )
}

export default index
